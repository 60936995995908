import React from 'react';
import cx from 'classnames';
import { Box, type BoxProps } from '@chakra-ui/react';

export { Box, BoxProps };

export type SpanProps = BoxProps;
export const Span = React.forwardRef<HTMLDivElement, BoxProps>(
  ({ className, display, ...props }, ref) => (
    <Box
      ref={ref}
      {...props}
      display={display || 'block'}
      as="span"
      className={cx('forge-box', 'forge-box--reset', className)}
    />
  )
);

export type PseudoBoxProps = BoxProps;
export const PseudoBox = React.forwardRef<HTMLDivElement, PseudoBoxProps>(
  ({ as, className, ...props }, ref) => {
    const BoxWithPseudoAs = React.forwardRef<HTMLDivElement, BoxProps>(
      (props, ref) => (
        <Box
          ref={ref}
          as={as}
          {...props}
        />
      )
    );
    return (
      <Box
        ref={ref}
        {...props}
        as={BoxWithPseudoAs}
        className={cx('forge-pseudobox', className)}
      />
    );
  }
);
