import React from 'react';
import {
  Checkbox as CCheckbox,
  type CheckboxProps as CCheckboxProps,
} from '@chakra-ui/react';
import { cartesianProps } from '../../../utils/cartesian-props';
import { FormControl } from '../form/';
import { CheckboxGroup } from './checkbox-group';

export interface CheckboxProps extends CCheckboxProps {
  variant?: 'default' | 'rowAlignTop';
}

export const checkboxTheme = {
  baseStyle: {
    container: {
      display: 'flex',
      my: 2,
    },
    control: {
      borderWidth: '1px',
      borderColor: 'inputBorder',
      borderRadius: '2px',
      _checked: {
        background: 'success',
        borderColor: 'successDark',
        _hover: { background: 'successDark', borderColor: 'successDark' },
      },
      _disabled: {
        background: 'disabled',
        borderColor: 'inputBorder',
      },
      _invalid: {
        borderColor: 'danger',
        borderWidth: '2px',
      },
    },
  },
  sizes: {
    md: {
      control: {
        boxSize: '18px',
      },
    },
    lg: {
      control: {
        boxSize: '22px',
      },
      label: {
        fontSize: 'md',
      },
    },
  },
  variants: {
    rowAlignTop: {
      control: {
        alignSelf: 'start',
        mt: '4px',
      },
    },
  },
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ variant = 'default', ...props }, ref) => {
    return (
      <CCheckbox
        ref={ref}
        variant={variant}
        {...props}
      />
    );
  }
);

/**
 * For testing
 */
const components = cartesianProps<CheckboxProps>(
  {
    variant: ['default', 'rowAlignTop'],
    value: ['ostaja', 'ilmoittaja'],
    defaultChecked: [true, undefined],
    isChecked: [true, false],
    isDisabled: [true, false],
    isInvalid: [true, false],
    isReadOnly: [true, false],
    isTruncated: [true, false],
    children: ['Ostaja'],
    onChange: [() => null],
  },
  Checkbox,
  ({ defaultChecked, ...props }, index) => (
    <Checkbox
      key={index}
      defaultChecked={props.isChecked ? undefined : defaultChecked}
      {...props}
    />
  )
);

export const toTesting = (
  <React.Fragment>
    <FormControl marginBottom={4}>
      <CheckboxGroup defaultValue={['ostaja']}>{components}</CheckboxGroup>
    </FormControl>
  </React.Fragment>
);
