import React, { ReactNode } from 'react';
import cx from 'classnames';
import {
  Alert as CAlert,
  AlertProps as CAlertProps,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { useTheme, ITokens } from '../../theme';
import { cartesianProps } from '../../utils/cartesian-props';
import {
  CheckCircle,
  Info,
  Warning,
  WarningCircle,
} from '@mezzoforte/forge-icons';
import { Flex } from './flex';
import { Heading } from './heading';
import { Text } from './text';
import { Box } from './box';

export const alertVariants = ['success', 'warning', 'danger', 'info'] as const;

type Variant = (typeof alertVariants)[number];
export interface AlertProps
  extends Omit<CAlertProps, 'variant' | 'title' | 'status'> {
  /**
   * Alert variant
   * <b>'success', 'warning', 'danger', 'info'</b>
   */
  variant?: Variant;
  /**
   * Title for the alert
   */
  title?: React.ReactNode;
  /**
   * If set to false hides alert icon. <b>Default is true. </b>
   */
  showIcon?: boolean;
  /**
   * Sematric heading-level for the title
   * <b>h1 | h2 | h3 | h4 | h5</b>
   */
  titleHeadingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  small?: boolean;
  inToast?: boolean;
}

type AlertStyles = Omit<CAlertProps, 'children' | 'variant'>;
const alertBaseStyle = ({ radii }: ITokens, small?: boolean): AlertStyles => ({
  display: small ? 'inline-flex' : 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  borderRadius: radii.basic,
  paddingX: small ? 0 : 3,
  paddingY: small ? 0 : 3,
});
const styles = (
  { colors }: ITokens,
  small?: boolean
): Record<Variant, AlertStyles> => ({
  success: {
    backgroundColor: colors.backgroundSuccess,
  },
  warning: {
    backgroundColor: colors.backgroundWarning,
  },
  danger: {
    backgroundColor: small ? 'transparent' : colors.backgroundDanger,
  },
  info: {
    backgroundColor: colors.backgroundInfo,
  },
});

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  (
    {
      showIcon = true,
      variant = 'info',
      small: propSmall = false, // Used only for Form components' error
      title,
      titleHeadingLevel,
      className,
      children,
      ...props
    },
    ref
  ) => {
    const small = variant === 'danger' && propSmall;
    const { forgeTokens } = useTheme();
    const iconAreaWidth = showIcon
      ? parseInt(forgeTokens.styles?.icon?.default?.size || '24px', 10) +
        parseInt(forgeTokens.space[3], 10) +
        'px'
      : 0;
    const iconSize = forgeTokens.styles.icon?.large.size;
    const iconColor = forgeTokens.colors[variant];

    const variantToIcon: (variant: Variant, size?: string) => ReactNode = (
      variant,
      size
    ) =>
      ({
        info: (
          <Info
            size={size ?? iconSize}
            color={iconColor}
            weight="fill"
          />
        ),
        success: (
          <CheckCircle
            size={size ?? iconSize}
            color={iconColor}
            weight="fill"
          />
        ),
        warning: (
          <Warning
            size={size ?? iconSize}
            color={iconColor}
            weight="fill"
          />
        ),
        danger: (
          <WarningCircle
            size={size ?? iconSize}
            color={iconColor}
            weight="fill"
          />
        ),
      })[variant];

    const withTitle = (
      <>
        <Flex
          alignItems="center"
          marginBottom={1}
        >
          {showIcon && (
            <Flex
              width={iconAreaWidth}
              alignItems="center"
              flexShrink={0}
            >
              {variantToIcon(variant)}
            </Flex>
          )}
          <AlertTitle>
            <Heading
              variant="h4"
              as={titleHeadingLevel}
              lineHeight="1"
            >
              {title}
            </Heading>
          </AlertTitle>
        </Flex>
        <AlertDescription ml={iconAreaWidth}>
          <Text as="div">{children}</Text>
        </AlertDescription>
      </>
    );

    const smallOrwithoutTitle = (
      <Flex alignItems="flex-start">
        <Flex
          flexShrink={0}
          alignItems="start"
        >
          {showIcon && <Box mr={2}>{variantToIcon(variant, '24px')}</Box>}
        </Flex>
        <AlertDescription display="inline-block">
          <Text
            as="div"
            color={small ? forgeTokens.colors[variant] : undefined}
          >
            {children}
          </Text>
        </AlertDescription>
      </Flex>
    );

    return (
      <CAlert
        ref={ref}
        {...alertBaseStyle(forgeTokens, small)}
        {...styles(forgeTokens, small)[variant]}
        {...props}
        className={cx('forge-alert', 'forge-box--reset', className)}
        status={variant === 'danger' ? 'error' : variant}
      >
        {!small && title ? withTitle : smallOrwithoutTitle}
      </CAlert>
    );
  }
);

/**
 * For testing
 */

const components = cartesianProps<AlertProps>(
  {
    variant: [...alertVariants],
    isTruncated: [true, false],
    title: ['Alert Title'],
    titleHeadingLevel: [undefined, 'h3'],
    children: ['Testing'],
  },
  Alert
);

export const toTesting = <>{components}</>;
