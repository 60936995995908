import React from 'react';
import cx from 'classnames';
import {
  Breadcrumb as CBreadcrumb,
  BreadcrumbProps as CBreadcrumbProps,
  BreadcrumbItemProps as CBreadcrumbItemProps,
  BreadcrumbLink as CBreadcrumbLink,
  BreadcrumbLinkProps as CBreadcrumbLinkProps,
  BreadcrumbItem as CBreadcrumbItem,
  BreadcrumbSeparator,
} from '@chakra-ui/react';
import { ArrowLeft, CaretRight } from '@mezzoforte/forge-icons';
import { LinkProps } from './link';
import { useTheme } from '../../theme';
import { cartesianProps } from '../../utils/cartesian-props';
import { Responsive } from '../../hooks/breakpoints';

export type LinkComponentProps = Omit<
  LinkProps,
  | 'as'
  | 'variant'
  | 'isExternal'
  | 'isDisabled'
  | 'withButtonSize'
  | 'inheritTypography'
>;

export interface LinkComponent extends LinkComponentProps {
  linkComponent?: CBreadcrumbItemProps['as'];
  label: string;
}

interface ForgeBreadcrumbItemProps extends CBreadcrumbItemProps {
  breadcrumb: LinkComponent;
}

const MobileBreadcrumbItem: React.FC<ForgeBreadcrumbItemProps> = ({
  breadcrumb,
  className,
  ...rest
}) => {
  const { forgeTokens } = useTheme();
  const { linkComponent, label, children, ...linkProps } = breadcrumb;
  return (
    <CBreadcrumbItem
      data-test="mobile-breadcrumb-item"
      {...rest}
      className={cx('forge-mobile-breadcrumb-item', className)}
      css={{ 'span[role=presentation]:last-of-type': { display: 'none' } }}
    >
      <BreadcrumbSeparator ml={0}>
        <ArrowLeft
          color="black"
          size={forgeTokens.styles.icon?.small.size}
        />
      </BreadcrumbSeparator>
      <CBreadcrumbLink
        as={linkComponent}
        fontWeight={forgeTokens.fontWeights.button}
        color={forgeTokens.colors.brand}
        minHeight="40px"
        py={forgeTokens.space[3]}
        {...linkProps}
      >
        {label ?? children}
      </CBreadcrumbLink>
    </CBreadcrumbItem>
  );
};

const BreadcrumbItem: React.FC<ForgeBreadcrumbItemProps> = ({
  breadcrumb: { linkComponent, label, children, ...linkProps },
  className,
  isCurrentPage,
  ...rest
}) => {
  const {
    forgeTokens: { space, colors, fontWeights },
  } = useTheme();

  const breadcrumbLinkProps: Omit<CBreadcrumbLinkProps, 'as' | 'children'> = {
    fontWeight: isCurrentPage ? fontWeights.body : fontWeights.button,
    color: isCurrentPage ? colors.text : colors.brand,
  };

  return (
    <CBreadcrumbItem
      isCurrentPage={isCurrentPage}
      {...rest}
      className={cx('forge-breadcrumb-item', className)}
    >
      <CBreadcrumbLink
        hideBelow={rest.hideBelow}
        minHeight="40px"
        py={space[3]}
        as={isCurrentPage ? 'span' : linkComponent}
        {...breadcrumbLinkProps}
        {...linkProps}
      >
        {label ?? children}
      </CBreadcrumbLink>
    </CBreadcrumbItem>
  );
};

export interface BreadcrumbProps extends CBreadcrumbProps {
  breadcrumbs: LinkComponent[];
  /**
   * Breakpoint for showing only the second to last item. Default: "sm"
   */
  collapsedBreakpoint?: Responsive;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  breadcrumbs,
  collapsedBreakpoint = 'sm',
  ...rest
}) => {
  const { listProps: customListProps, ...others } = rest;
  const { forgeTokens } = useTheme();
  const iconSize = forgeTokens.styles.icon?.small.size;

  const listProps: CBreadcrumbProps['listProps'] = {
    ...customListProps,
    display: 'inline-flex',
    overflowWrap: 'break-word',
    overflowX: 'auto',
    scrollBehavior: 'smooth',
    scrollSnapType: 'x proximity',
    width: '100%',
    css: {
      scrollbarWidth: 'none',
      'span[role=presentation]': {
        display: 'inline-flex',
      },
    },
  };
  const itemProps: CBreadcrumbItemProps = {
    fontFamily: 'heading',
    fontSize: 'sm',
    lineHeight: 1,
    letterSpacing: forgeTokens.letterSpacings.bodySmall,
    alignItems: 'center',
    minW: 'fit-content',
    scrollSnapAlign: 'start end',
  };

  const breadcrumbSeparator = (
    <CaretRight
      color="black"
      size={iconSize}
    />
  );

  const breadcrumb = breadcrumbs.at(-2);

  return (
    <CBreadcrumb
      separator={breadcrumbSeparator}
      listProps={{ ...listProps }}
      borderBottomWidth={forgeTokens.borderWidths.basic}
      borderBottomColor={forgeTokens.colors.lineBorder}
      borderBottomStyle="solid"
      {...others}
    >
      {breadcrumb && (
        <MobileBreadcrumbItem
          hideFrom={collapsedBreakpoint}
          breadcrumb={breadcrumb}
          isCurrentPage={false}
          {...itemProps}
        />
      )}

      {breadcrumbs.map((bc, index) => {
        const isLast = index === breadcrumbs.length - 1;
        return (
          <BreadcrumbItem
            key={`${index}-${bc.href}`}
            breadcrumb={bc}
            hideBelow={collapsedBreakpoint}
            isCurrentPage={isLast}
            {...itemProps}
          />
        );
      })}
    </CBreadcrumb>
  );
};

/**
 * For testing
 */
const bcs = [
  { label: 'First', href: '/first' },
  { label: 'Second', href: '/second' },
  { label: 'Third', href: '/third' },
];
interface ForTestingProps extends BreadcrumbProps {
  children?: React.ReactNode;
}
const components = cartesianProps<ForTestingProps>(
  {
    breadcrumbs: [bcs],
    collapsedBreakpoint: [undefined, 'md'],
  },
  Breadcrumb
);

export const toTesting = <>{components}</>;
