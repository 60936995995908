import React from 'react';
import {
  Button as CButton,
  ButtonProps as CButtonProps,
} from '@chakra-ui/react';
import cx from 'classnames';
import { Question } from '@mezzoforte/forge-icons';
import { useTheme, ITokens } from '../../theme';
import { Spinner } from './spinner';
import { cartesianProps } from '../../utils/cartesian-props';
import { ButtonGroup } from './button-group';
import { selectedTypographyFromTokens } from './text';
import { Box } from './box';
import { Flex } from './flex';
import { Tooltip } from './tooltip';

const buttonVariants = [
  'default',
  'default-hero',
  'default-lists',
  'primary',
  'primary-hero',
  'primary-lists',
  'secondary',
  'secondary-hero',
  'secondary-lists',
  'highlight',
  'highlight-hero',
  'highlight-lists',
  'danger',
  'danger-primary',
  'danger-hero',
  'danger-lists',
  'danger-primary-hero',
  'danger-primary-lists',
] as const;

export type Variant = (typeof buttonVariants)[number];

export interface ButtonProps
  extends Omit<
    CButtonProps,
    'children' | 'variant' | 'colorScheme' | 'css' | 'disabled' | 'isDisabled'
  > {
  /**
   * Button variant
   */
  variant?: Variant;
  css?: CButtonProps['css'];
  /**
   * If `true`, the button will show a spinner.
   */
  isLoading?: boolean;
  /**
   * If `true`, the button will be styled in it's active state.
   */
  isActive?: boolean;
  /**
   * Button disabled state.
   *
   * If disabled state
   * *boolean* Button is rendered without tooltip.
   *
   * Other ReactNode types renders tooltip explaining why button is
   * disabled.
   */
  isDisabled?: React.ReactNode;
  disabled?: React.ReactNode;
  /**
   * The label to show in the button when `isLoading` is true
   * If no text is passed, it only shows the spinner
   */
  loadingText?: string;
  /**
   * The html button type to use.
   */
  type?: 'button' | 'reset' | 'submit';
  icon?: React.ReactNode;
  children?: React.ReactNode; // Optional because not able to implement interface that has either icon or children
}

const ButtonBase = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant,
      className,
      isLoading,
      loadingText,
      children,
      'aria-checked': ariaChecked,
      isDisabled,
      disabled,
      icon,
      ...props
    },
    ref
  ) => {
    const { forgeTokens } = useTheme();
    if (!!icon && !props['aria-label']) {
      console.error('Icon Button must have aria-label!');
    }
    const disabledState = !!isDisabled || !!disabled;
    const styleVariant = variant ?? (icon ? 'icon-only' : 'default');
    variant = variant ?? 'default';
    const content = () => {
      if (icon) {
        return icon;
      }
      if (!children) {
        // Not able to implement interface that has either icon or children
        console.warn('Button missing children!');
        return null;
      }
      return children;
    };
    const minHeight =
      forgeTokens.styles?.button?.[variant]?.minHeight ||
      forgeTokens.styles?.button?.mintouch?.minHeight ||
      0;
    const disabledHoverStyles = isDisabled ? { _hover: {} } : {};

    return (
      <CButton
        aria-checked={ariaChecked}
        aria-disabled={disabledState}
        ref={ref}
        border={forgeTokens.borders.input}
        {...styles(forgeTokens).base}
        {...styles(forgeTokens)[styleVariant]}
        minHeight={minHeight}
        paddingY={forgeTokens.styles?.button?.[variant]?.paddingY ?? 0}
        minWidth={forgeTokens.styles?.button?.mintouch?.minWidth ?? 0}
        {...disabledHoverStyles}
        {...props}
        _focus={{
          outline: 'none',
          boxShadow: focusBoxShadow(forgeTokens)[variant],
          ...props._focus,
        }}
        _disabled={{
          opacity: forgeTokens.opacities.disabled,
          cursor: 'not-allowed',
          boxShadow: 'none',
          ...{
            color: [
              'default',
              'default-hero',
              'default-lists',
              'danger',
              'danger-hero',
              'danger-lists',
            ].includes(variant)
              ? forgeTokens.colors.text
              : forgeTokens.colors.textInvertDisabled,
          },
          ...props._disabled,
        }}
        className={cx('forge-button--reset', 'forge-button', className)}
      >
        {isLoading ? (
          <Box>
            <Box
              opacity={0}
              height="0px"
            >
              {content()}
            </Box>
            <Flex
              alignItems="center"
              justifyContent="center"
            >
              <Spinner
                mr={loadingText ? 2 : 0}
                size="sm"
              />
              {loadingText}
            </Flex>
          </Box>
        ) : (
          <Flex
            as="span"
            alignSelf="center"
            alignItems="center"
            justifyContent="center"
          >
            {content()}
          </Flex>
        )}
      </CButton>
    );
  }
);

const smallDisabledIcon: (Variant | '')[] = [
  'default-lists',
  'primary-lists',
  'danger-lists',
  'secondary-lists',
  'highlight-lists',
  'danger-primary-lists',
];

interface DisabledButtonWithInfoProps extends ButtonProps {
  disabledInfo: React.ReactNode;
}

const DisabledButtonWithInfo = React.forwardRef<
  HTMLButtonElement,
  DisabledButtonWithInfoProps
>((props, ref) => {
  const { disabledInfo, ...restProps } = props;
  const { children, variant } = props;
  return (
    <Tooltip info={disabledInfo}>
      <ButtonBase
        ref={ref}
        {...restProps}
        onClick={undefined}
      >
        {children}{' '}
        <Flex
          as="span"
          pl={1}
        >
          <Question
            size={smallDisabledIcon.includes(variant ?? '') ? 16 : 24}
          />
        </Flex>
      </ButtonBase>
    </Tooltip>
  );
});

// TypeguardDisabled
const isBoolean = (attr: React.ReactNode): attr is boolean =>
  attr !== undefined && typeof attr === 'boolean' && attr;

const isReactNode = (attr: React.ReactNode) => !isBoolean(attr) && attr;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { isDisabled, disabled } = props;
    const disabledInfo = isReactNode(isDisabled) || isReactNode(disabled);
    const disabledFlag = isBoolean(isDisabled) || isBoolean(disabled);
    const disableClickAction = disabledFlag ? { onClick: undefined } : {};

    return disabledInfo ? (
      <DisabledButtonWithInfo
        ref={ref}
        {...props}
        disabledInfo={disabledInfo}
      />
    ) : (
      <ButtonBase
        ref={ref}
        {...props}
        {...disableClickAction}
      />
    );
  }
);

export type ButtonStyles = Omit<CButtonProps, 'children'>;
type BaseStyles =
  | 'base'
  | 'primary'
  | 'secondary'
  | 'default'
  | 'danger'
  | 'hero'
  | 'highlight'
  | 'danger-primary-colors'
  | 'lists';
export const baseStyles = (
  forgeTokens: ITokens
): Record<BaseStyles, ButtonStyles> => ({
  base: {
    ...selectedTypographyFromTokens(forgeTokens, 'button'),
    colorScheme: 'orange', // Just as a reset
    variant: 'outline',
    color: forgeTokens.colors.brand,
    backgroundColor: 'transparent',
    size: 'sm',
    height: 'auto',
    px: 4,
    borderRadius: forgeTokens.radii.basic,
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    _active: {
      backgroundColor: 'transparent',
    },
  },
  primary: {
    variant: 'solid',
    color: forgeTokens.colors.textInvert,
    textShadow: forgeTokens.shadows.basic,
    backgroundColor: forgeTokens.colors.brand,
    borderColor: forgeTokens.colors.brand,
    _hover: {
      bg: forgeTokens.colors.brandDark,
      borderColor: forgeTokens.colors.brandDark,
    },
    _active: {
      bg: forgeTokens.colors.brandDark,
      borderColor: forgeTokens.colors.brandDark,
    },
  },
  secondary: {
    variant: 'solid',
    color: forgeTokens.colors.textInvert,
    textShadow: forgeTokens.shadows.basic,
    backgroundColor: forgeTokens.colors.brandSecondary,
    borderColor: forgeTokens.colors.brandSecondary,
    _hover: {
      bg: forgeTokens.colors.brandSecondary,
      opacity: 0.94,
      borderColor: forgeTokens.colors.brandSecondary,
    },
    _active: {
      //bg: forgeTokens.colors.brandDark,
      borderColor: forgeTokens.colors.brandSecondary,
    },
  },
  default: {
    colorScheme: 'black',
    color: 'black',
    backgroundColor: 'background',
    borderColor: 'inputBorder',
    _hover: { bg: 'backgroundGray' },
    _active: { bg: 'backgroundGray' },
  },
  danger: {
    colorScheme: 'red', // Just as a reset
    backgroundColor: 'background',
    borderColor: 'inputBorder',
    _hover: {
      color: 'dangerDark',
      bg: 'backgroundDanger',
      borderColor: 'backgroundDanger',
    },
    _active: {
      color: 'dangerDark',
      bg: 'backgroundDanger',
      borderColor: 'backgroundDanger',
    },
    color: 'danger',
  },
  hero: {
    ...selectedTypographyFromTokens(forgeTokens, 'buttonLarge'),
    size: 'md',
  },
  highlight: {
    variant: 'solid',
    color: forgeTokens.colors.textInvert,
    textShadow: forgeTokens.shadows.basic,
    backgroundColor: forgeTokens.colors.success,
    borderColor: forgeTokens.colors.success,

    _hover: {
      bg: forgeTokens.colors.successDark,
      borderColor: forgeTokens.colors.successDark,
    },
    _active: {
      bg: forgeTokens.colors.successDark,
      borderColor: forgeTokens.colors.successDark,
    },
  },
  'danger-primary-colors': {
    backgroundColor: 'danger',
    borderColor: 'danger',
    _hover: { bg: 'dangerDark', borderColor: 'dangerDark' },
    _active: { bg: 'dangerDark', borderColor: 'dangerDark' },
  },
  lists: {
    ...selectedTypographyFromTokens(forgeTokens, 'buttonSmall'),
    size: 'xs',
    px: 2,
  },
});

const focusBoxShadow = (forgeTokens: ITokens): Record<Variant, string> => ({
  highlight: forgeTokens.shadows.outlineHighlight,
  'highlight-hero': forgeTokens.shadows.outlineHighlight,
  'highlight-lists': forgeTokens.shadows.outlineHighlight,
  secondary: forgeTokens.shadows.outlineSecondary,
  'secondary-hero': forgeTokens.shadows.outlineSecondary,
  'secondary-lists': forgeTokens.shadows.outlineSecondary,
  default: forgeTokens.shadows.outline,
  'default-hero': forgeTokens.shadows.outline,
  'default-lists': forgeTokens.shadows.outline,
  primary: forgeTokens.shadows.outline,
  'primary-hero': forgeTokens.shadows.outline,
  'primary-lists': forgeTokens.shadows.outline,
  danger: forgeTokens.shadows.outlineDanger,
  'danger-hero': forgeTokens.shadows.outlineDanger,
  'danger-lists': forgeTokens.shadows.outlineDanger,
  'danger-primary': forgeTokens.shadows.outlineDanger,
  'danger-primary-hero': forgeTokens.shadows.outlineDanger,
  'danger-primary-lists': forgeTokens.shadows.outlineDanger,
});

const styles = (
  forgeTokens: ITokens
): Record<Variant | 'base' | 'icon-only', ButtonStyles> => {
  const getBaseStyles = baseStyles(forgeTokens);
  const listsBorderRadius = forgeTokens.radii.subtle;
  return {
    base: getBaseStyles.base,
    primary: getBaseStyles.primary,
    secondary: getBaseStyles.secondary,
    default: getBaseStyles.default,
    danger: getBaseStyles.danger,
    'danger-primary': {
      ...getBaseStyles.primary,
      ...getBaseStyles['danger-primary-colors'],
    },
    'default-hero': {
      ...getBaseStyles.default,
      ...getBaseStyles.hero,
    },
    'primary-hero': {
      ...getBaseStyles.primary,
      ...getBaseStyles.hero,
    },
    'secondary-hero': {
      ...getBaseStyles.secondary,
      ...getBaseStyles.hero,
    },
    highlight: getBaseStyles.highlight,
    'highlight-hero': {
      ...getBaseStyles.highlight,
      ...getBaseStyles.hero,
    },
    'highlight-lists': {
      ...getBaseStyles.highlight,
      ...getBaseStyles.lists,
      borderRadius: listsBorderRadius,
    },
    'danger-hero': {
      ...getBaseStyles.danger,
      ...getBaseStyles.hero,
    },
    'danger-primary-hero': {
      ...getBaseStyles.primary,
      ...getBaseStyles.hero,
      ...getBaseStyles['danger-primary-colors'],
    },
    'default-lists': {
      ...getBaseStyles.default,
      ...getBaseStyles.lists,
      borderRadius: listsBorderRadius,
    },
    'primary-lists': {
      ...getBaseStyles.primary,
      ...getBaseStyles.lists,
      borderRadius: listsBorderRadius,
    },
    'secondary-lists': {
      ...getBaseStyles.secondary,
      ...getBaseStyles.lists,
      borderRadius: listsBorderRadius,
    },
    'danger-lists': {
      ...getBaseStyles.danger,
      ...getBaseStyles.lists,
      borderRadius: listsBorderRadius,
    },
    'danger-primary-lists': {
      ...getBaseStyles.primary,
      ...getBaseStyles.lists,
      ...getBaseStyles['danger-primary-colors'],
      borderRadius: listsBorderRadius,
    },
    'icon-only': {
      padding: forgeTokens.space[1],
      border: '0',
      color: forgeTokens.colors.text,
      backgroundColor: 'transparent',
      _hover: {
        backgroundColor: forgeTokens.colors.backgroundGray,
      },
    },
  };
};

/**
 * For testing
 */
const components = (variant: Variant) =>
  cartesianProps<ButtonProps>(
    {
      variant: [variant],
      loadingText: ['Loading...'],
      isLoading: [false, true],
      isDisabled: [false, 'This is disabled'],
      isTruncated: [false, true],
      children: ['Short', 'Longggggggggg Text'],
      id: ['test-id'],
      onClick: [() => null],
    },
    Button,
    (props, index) => (
      <Button
        key={index}
        {...props}
      />
    )
  );

export const toTesting = // Too many components to test at once, need to split in groups.
  (
    <>
      {buttonVariants.map((variant) => (
        <ButtonGroup
          width="500px"
          margin={1}
          data-testgroup={variant}
          key={variant}
        >
          {components(variant)}
        </ButtonGroup>
      ))}
    </>
  );
