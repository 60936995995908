import React, { useCallback, useState } from 'react';
import { Eye, EyeSlash } from '@mezzoforte/forge-icons';
import cx from 'classnames';
import { TextInput, TextInputProps } from './text-input';
import { FormControl } from '../../form';
import { cartesianProps } from '../../../../utils/cartesian-props';
import { Box } from '../../box';

export type PasswordInputProps = TextInputProps;

export const PasswordInput = React.forwardRef<
  HTMLInputElement,
  PasswordInputProps
>((props: PasswordInputProps, ref) => {
  const [isVisible, setIsVisible] = useState(false);

  const onToggleClick = useCallback(() => {
    setIsVisible((v) => !v);
  }, []);

  const Icon = isVisible ? Eye : EyeSlash;

  return (
    <TextInput
      ref={ref}
      {...props}
      className={cx('forge-passwordinput', props.className)}
      type={isVisible ? 'text' : 'password'}
      rightAddon={
        <Box
          display="inline-flex"
          onClick={onToggleClick}
          cursor="pointer"
        >
          <Icon />
        </Box>
      }
    />
  );
});

/**
 * For testing
 */
const components = cartesianProps<TextInputProps>(
  {
    label: ['Testing label'],
    id: ['testing-id'],
    helperText: ['Helper text', undefined],
    errorInfo: ['Error info', undefined],
    isDisabled: [true, false],
    isFullWidth: [true, false],
    isInvalid: [true, false],
    isReadOnly: [true, false],
    isRequired: [true, false],
    leftAddon: ['Left addon', undefined],
  },
  TextInput
);

export const toTesting = <FormControl width="500px">{components}</FormControl>;
