import React from 'react';
import { Text, type TextProps } from '../text';

export type FormHelperTextProps = TextProps;

export const FormHelperText = React.forwardRef<HTMLDivElement, TextProps>(
  ({ children, ...props }, ref) => (
    <Text
      ref={ref}
      variant="subdued"
      fontSize="sm"
      {...props}
    >
      {children}
    </Text>
  )
);
