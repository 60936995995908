import React from 'react';
import { useMediaQuery, useQuery } from '@chakra-ui/react';
import { default as RCPagination } from 'rc-pagination';
import {
  type PaginationLocale,
  type PaginationProps as RCPaginationProps,
} from 'rc-pagination';
import { CaretLeft, CaretRight } from '@mezzoforte/forge-icons';
import { Box } from './box';
import {
  type CustomPaginationStyle,
  usePaginationStyles,
} from '../../hooks/usePaginationStyles';
import { type Responsive } from '../../hooks/breakpoints';
import { cartesianProps } from '../../utils/cartesian-props';

export const paginationVariants = ['default', 'small'] as const;

type Variant = (typeof paginationVariants)[number];

const defaultLang: PaginationLocale = {
  prev_page: 'Edellinen sivu',
  next_page: 'Seuraava sivu',
  page: ' sivulle',
  items_per_page: '/ sivu',
  jump_to: 'Siirry',
  next_3: 'Seuraavat 3 sivua',
  prev_3: 'Edelliset 3 sivua',
  next_5: 'Seuraavat 5 sivua',
  prev_5: 'Edelliset 5 sivua',
  page_size: 'PAGE SIZE',
  jump_to_confirm: 'Haluatko siirtyä sivulle',
};

type LimitedRCPaginationProps = Pick<
  RCPaginationProps,
  | 'current'
  | 'defaultPageSize'
  | 'total'
  | 'onChange'
  | 'hideOnSinglePage'
  | 'itemRender'
  | 'pageSize'
  | 'prevIcon'
  | 'nextIcon'
  | 'jumpPrevIcon'
  | 'jumpNextIcon'
  | 'showPrevNextJumpers'
>;

export interface PaginationProps extends LimitedRCPaginationProps {
  /**
   * Pagination variant
   */
  variant?: Variant;

  /**
   * Breakpoint to switch between mobile/desktop.
   * previous and next jumpers are visible if
   * screen is above breakpoint.sm
   */
  responsive?: Responsive;

  /**
   * Default language
   */
  defaultLang?: PaginationLocale;

  /**
   * To override component styling
   */
  customStyle?: CustomPaginationStyle;

  /**
   * Whether Pagination is disabled
   */
  isDisabled?: boolean;

  /**
   * Backward support for ReactPaginate.
   * same as 'total' in rc-pagination.
   */
  pageCount?: number;

  /**
   * Backward support for ReactPaginate.
   * same as 'current' in rc-pagination.
   */
  forcePage?: number;
}

export const Pagination = ({
  variant = 'default',
  responsive = 'md',
  defaultLang: customDefaultLang,
  pageCount,
  total,
  forcePage,
  current,
  customStyle,
  isDisabled,
  ...props
}: PaginationProps) => {
  const paginationDefaultCss = usePaginationStyles(customStyle);
  const query = useQuery({ below: responsive });
  const query2 = useQuery({ above: 'sm' });
  const [isBelowBreakpoint, isLargerThanSm] = useMediaQuery([query, query2], {
    ssr: true,
    fallback: false,
  });

  const paginationCss = { ...paginationDefaultCss };
  const isSmallVariant = variant === 'small' || isBelowBreakpoint;
  const language = { ...defaultLang, ...customDefaultLang };

  return (
    <Box
      as="div"
      display="inline-flex"
      css={paginationCss}
    >
      <Box
        as={RCPagination}
        total={pageCount ?? total}
        locale={language}
        current={forcePage ?? current}
        disabled={isDisabled}
        prevIcon={
          <CaretLeft
            aria-label={language.prev_page}
            aria-hidden="true"
            size={16}
          />
        }
        nextIcon={
          <CaretRight
            aria-label={language.next_page}
            aria-hidden="true"
            size={16}
          />
        }
        showTitle={false}
        showSizeChanger={false}
        showPrevNextJumpers={isLargerThanSm}
        defaultPageSize={1}
        {...props}
        showLessItems={isSmallVariant}
        prefixCls="forge-pagination"
      />
    </Box>
  );
};

/**
 * For testing
 */
interface ForTestingProps extends PaginationProps {
  children?: React.ReactNode;
}

const components = [
  cartesianProps<ForTestingProps>(
    {
      variant: [...paginationVariants],
      total: [6],
      current: [undefined, 2, 3, 4, 5],
      onChange: [() => null],
    },
    Pagination
  ),
  cartesianProps<ForTestingProps>(
    {
      variant: [...paginationVariants],
      total: [114],
      current: [undefined, 1, 113],
      onChange: [() => null],
    },
    Pagination
  ),
];

export const toTesting = <React.Fragment>{...components}</React.Fragment>;
