import React from 'react';
import cx from 'classnames';
import { InputLeftElement, InputRightElement } from '@chakra-ui/react';
import { MagnifyingGlass, X } from '@mezzoforte/forge-icons';
import { InputGroup } from './input';
import { TextInput, TextInputProps } from './text-input';
import { Button, ButtonProps } from '../../button';
import { cartesianProps } from '../../../../utils/cartesian-props';
import { FormControl } from '../../form';
import { FormLabel } from '../form-label';
import { FormHelperText } from '../form-helper-text';
import { Alert } from '../../alert';
import { useTheme } from '../../../../theme';

const clearButtonStyles: TextInputProps['css'] = {
  ':focus': {
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
  },
  ':active': {
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
  },
  ':hover': { backgroundColor: 'transparent' },
};
export interface SearchInputProps extends Omit<TextInputProps, 'label'> {
  onClickReset: ButtonProps['onClick'];
  label?: TextInputProps['label'];
}

export const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  (
    {
      className,
      onClickReset,
      label,
      id: customId,
      isInvalid,
      errorInfo,
      helperText,
      ...props
    },
    ref
  ) => {
    const id = customId ?? React.useId();
    const idString = `forge-searchinput-${id}`;
    const idHelperString = `${idString}-helper`;
    const { forgeTokens } = useTheme();
    const isEmptyVal = !props.value;

    return (
      <>
        {label && <FormLabel htmlFor={idString}>{label}</FormLabel>}
        <InputGroup
          marginLeft="auto"
          className="forge-searchinput-container"
        >
          <InputLeftElement>
            <Button
              type="submit"
              aria-label="search"
              className="forge-button--reset"
              isDisabled={isEmptyVal}
              css={clearButtonStyles}
              icon={
                <MagnifyingGlass
                  color={forgeTokens.colors.subdued}
                  weight="light"
                />
              }
            />
          </InputLeftElement>
          <TextInput
            ref={ref}
            label={label}
            withoutLabel
            width="100%"
            paddingLeft="40px"
            paddingRight="40px"
            className={cx('forge-searchinput', className)}
            id={idString}
            role="searchbox"
            {...props}
          />
          {!isEmptyVal && (
            <InputRightElement>
              <Button
                aria-label="Reset"
                border="0"
                height="24px"
                minHeight="24px"
                minWidth="24px"
                onClick={onClickReset}
                background="transparent"
                icon={
                  <X
                    size={16}
                    color={forgeTokens.colors.text}
                  />
                }
              />
            </InputRightElement>
          )}
        </InputGroup>
        {isInvalid && errorInfo && (
          <Alert
            variant="danger"
            small
          >
            {errorInfo}
          </Alert>
        )}
        <FormHelperText id={idHelperString}>{helperText}</FormHelperText>
      </>
    );
  }
);

/**
 * For testing
 */

const components = cartesianProps<SearchInputProps>(
  {
    label: ['Testing label'],
    id: ['testing-id'],
    helperText: ['Helper text', undefined],
    errorInfo: ['Error info', undefined],
    isDisabled: [true, false, undefined],
    isFullWidth: [true, false],
    isInvalid: [true, false],
    isReadOnly: [true, false],
    isRequired: [true, false],
    onClickReset: [() => null],
  },
  SearchInput
);

export const toTesting = <FormControl width="500px">{components}</FormControl>;
